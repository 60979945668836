import './Inicio.css';
import React, { useEffect, useState } from 'react';
import YoutubeEmbed from './componentes/js/Youtube';
import Header from './componentes/js/Header';
import Footer from './componentes/js/Footer';
import Galeria from './componentes/js/Galeria';
import Equipe from './componentes/js/CarouselProfessores';

function Inicio() {
  const [appStoreLink, setAppStoreLink] = useState('');
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
      setIsMobile(true);
    }

    // Define o link conforme o sistema operacional
    if (/android/i.test(userAgent)) {
      setAppStoreLink('https://play.google.com/store/apps/details?id=br.com.terabytesolucoes.terasmart.escolarmanagersmart&hl=pt_BR&pli=1');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setAppStoreLink('https://apps.apple.com/no/app/escolar-manager-smart/id6736771905'); // Substitua pelo link correto da App Store
    }

    const elementosAnimados = document.querySelectorAll('.elemento-animado, .elemento-animado-esquerda, .elemento-animado-direita, .elemento-animado-down');

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('entrou-na-tela');
        }
      });
    }, { threshold: 0.4 });

    elementosAnimados.forEach(elemento => {
      observer.observe(elemento);
    });

    return () => {
      elementosAnimados.forEach(elemento => {
        observer.unobserve(elemento);
      });
    };
  }, []);

  const importAllImages = (requireContext) => {
    return requireContext.keys().map(requireContext);
  };
  
  const listOfImages = importAllImages(require.context('./galeria', false, /\.(png|jpe?g|svg)$/));

  return (
    <div>
      <Header />

      <div className='fundoInicio'>
        <div className="onda onda-top"></div>
        <Galeria listOfImages={listOfImages} />
      </div>

      <div class="card-container">
    <a href='ensino-fundamental-1' class="carta-inicio">
        <div class="card-inner">
            <div class="card-front">
                <div class="card-content">
                    <img src="assets/images/banners/fundamental.png" alt="Imagem 1" />
                </div>
            </div>
            <div class="card-back">
              <img src="assets/images/banners/fundamentalB.png" alt="Imagem 2" />
            </div>
        </div>
    </a>

    <a href='ensino-fundamental-2' class="carta-inicio">
        <div class="card-inner">
            <div class="card-front">
                <div class="card-content">
                    <img src="assets/images/banners/fundamental2.png" alt="Imagem 2" />
                </div>
            </div>
            <div class="card-back">
                  <img src="assets/images/banners/fundamental2B.png" alt="Imagem 2" />
            </div>
        </div>
    </a>

    <a href='ensino-medio' class="carta-inicio">
        <div class="card-inner">
            <div class="card-front">
                <div class="card-content">
                    <img src="assets/images/banners/medio.png" alt="Imagem 3" />
                </div>
            </div>
            <div class="card-back">
              <img src="assets/images/banners/medioB.png" alt="Imagem 2" />
            </div>
        </div>
    </a>
  </div>

      <div class="alunoOnline elemento-animado-esquerda">
          <div class="centralAlunoContainer">
              <img src="assets/images/estudantes/estudante_central_alunos.png" alt="Aluno" class="imagemAluno" />
              <div class="botaoContainer">
                  <a href="https://aluno.escolarmanageronline.com.br/colegiolidergo" rel='noreferrer' target='_blank'><button class="centralAluno">ACESSAR CENTRAL DO ALUNO</button></a>
                  <p class="subTitulo">Não é aluno? <a href='https://wa.me/5562998271550' target='_blank' rel='noreferrer'>Matricule-se!</a></p>
              </div>
          </div>
      </div>

      <div className="sobrenos-container elemento-animado">
        <div className="fundo">
          <h1>Apresentação do nosso colégio</h1>
        </div>
        <div className="video">
          <YoutubeEmbed url="https://www.youtube.com/embed/isuSIrnQHCI?vq=hd720p" />
        </div>
      </div>

      <Equipe />
    
      <div className="grid-container">
        <div className="grid-item elemento-animado-direita">
          <div className="texto">
            <h1>Proposta Pedagógica</h1>
            <hr className="amarelo"/>
            <p>
              O Colégio Líder oferece o Ensino Fundamental e o Ensino Médio, 
              apoiado no desenvolvimento de uma consciência crítica, na autonomia, 
              responsabilidade e criatividade para uma boa execução do processo ensino-aprendizagem, 
              e ainda, busca dar direcionamento à prática educativa, visando à formação integral 
              dos alunos, em seus aspectos físicos, intelectuais e emocionais.
            </p>
          </div>
          <div className="imagem">
            <img src="assets/images/proposta-pedagogica.jpg" alt="Proposta Pedagógica"/>
          </div>
        </div>
        <div className="grid-item alternado elemento-animado-esquerda">
          <div className="texto">
            <h1>O Colégio</h1>
            <hr className="amarelo"/>
            <p id='textoAlternado'>
              O Colégio Líder é mais do que uma escola, é um projeto de vida para os 
              seus alunos. Aqui, nós oferecemos uma educação de excelência, baseada em valores 
              éticos, humanos e sociais, que contribuem para a formação integral dos nossos 
              estudantes. Nossos alunos do Ensino Fundamental e do Ensino Médio são estimulados a 
              descobrir seus talentos, suas paixões, suas vocações e suas missões. Eles aprendem a 
              pensar criticamente, a agir com autonomia, a assumir responsabilidades e a criar soluções 
              inovadoras. Nossa equipe pedagógica é formada por profissionais competentes e dedicados, 
              que acompanham de perto o desenvolvimento de cada aluno, respeitando suas individualidades 
              e potencializando suas capacidades. Venha conhecer o Colégio Líder, uma escola que transforma vidas!
            </p>
          </div>
          <div className="imagem">
            <img src="assets/images/o-colegio.jpeg" alt="Colégio Líder"/>
          </div>
        </div>
      </div>
      <div class="lider-container">
        <div class="content">
          <h2>Escolar Manager - Colégio Líder</h2>
          <div className='app-wrapper'>
            <img src="assets/images/app1.jpg" alt="App 1"/>
            <img src="assets/images/app2.png" alt="App 2"/>
            <img src="assets/images/app3.png" alt="App 3"/>
            <img src="assets/images/app4.png" alt="App 4"/>
          </div>
          <p>
            O app do colégio líder é uma ferramenta incrível para os alunos e os pais. 
            Com ele, você pode consultar suas notas, ver as direções do dia a dia, 
            receber comunicados importantes e muito mais. Os pais também podem acompanhar o 
            consumo dos filhos na cantina, saber o que eles estão comendo e quanto estão gastando. 
            O app do colégio líder é o melhor jeito de ficar por dentro de tudo que acontece na escola. Baixe já!
          </p>
          <div className="botao">
            {isMobile ? (
              <a href={appStoreLink} target="_blank" rel="noreferrer">
                <button>Baixar APP</button>
              </a>
            ) : (
              <a></a>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Inicio;
